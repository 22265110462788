import { Node, ReactFlow, useEdgesState, useNodesState } from "@xyflow/react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { edgeTypes, nodeTypes } from "../helpers/edgeAndNodeTypes";
import useCollapseAndExpand from "../hooks/useCollapseAndExpand";
import useExport from "../hooks/useExport";
import useFetchAndSubscribeToMindMap from "../hooks/useFetchAndSubscribeToMindMap";
import useGlobalContext from "../hooks/useGlobalContext";
import useHandleMessage from "../hooks/useHandleMessage";
import useHandleNodeClick from "../hooks/useHandleNodeClick";
import useHandleSelectedNode from "../hooks/useHandleSelectedNode";
import useLayOutWithD3Hierarchy from "../hooks/useLayOutWithD3Hierarchy";
import useNavigateToNodes from "../hooks/useNavigateToNodes";
import usePersistEditedEdgesAndNodes from "../hooks/usePersistEditedEdgesAndNodes";
import ControlsPanel from "./ControlsPanel";

export default function MindMap() {
  const [edges, , onEdgesChange] = useEdgesState([]);
  const [nodes, , onNodesChange] = useNodesState([]);

  const [searchParams] = useSearchParams();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const showControls = searchParams.get("controls") === "true";
  const { collapse, expand } = useCollapseAndExpand();
  const { handleNodeClick } = useHandleNodeClick();
  const { isEditing } = useGlobalContext();
  const { isLayingOut, layOutWithD3Hierarchy } = useLayOutWithD3Hierarchy();
  const { navigateToNextNode, navigateToPreviousNode } = useNavigateToNodes();

  const defaultViewport = {
    x: typeof window !== "undefined" ? window.innerWidth / 2 : 0,
    y: typeof window !== "undefined" ? window.innerHeight / 2 : 0,
    zoom: 0.6,
  };

  useExport();

  useFetchAndSubscribeToMindMap();

  useHandleMessage();

  useHandleSelectedNode();

  useEffect(() => {
    const areAllNodesLayouted = nodes.every(
      (node: Node) => node.position.x !== 0 && node.position.y !== 0,
    );

    if (!areAllNodesLayouted) {
      layOutWithD3Hierarchy();
    }
  }, [layOutWithD3Hierarchy, nodes]);

  return (
    <ReactFlow
      className="bg-custom-background"
      defaultViewport={defaultViewport}
      edgeTypes={edgeTypes}
      edges={edges}
      maxZoom={0.8}
      minZoom={0.1}
      nodeTypes={nodeTypes}
      nodes={nodes}
      nodesConnectable={false}
      nodesDraggable
      onEdgesChange={onEdgesChange}
      onNodeClick={handleNodeClick}
      onNodeDragStop={persistEditedEdgesAndNodes}
      onNodesChange={onNodesChange}
      panOnScroll
      proOptions={{ account: "paid-pro", hideAttribution: true }}
      selectNodesOnDrag={false}
      zoomOnDoubleClick={!isEditing}
    >
      {showControls && (
        <ControlsPanel
          collapse={collapse}
          expand={expand}
          isLayingOut={isLayingOut}
          navigateToNextNode={navigateToNextNode}
          navigateToPreviousNode={navigateToPreviousNode}
        />
      )}
    </ReactFlow>
  );
}
