import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import { GlobalContextProvider } from "./context/GlobalContext";
import "./helpers/sentry";
import "./i18n";
import "./index.css";
import ErrorPage from "./pages/ErrorPage";
import MindMapPage from "./pages/MindMapPage";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  { element: <App />, errorElement: <ErrorPage />, path: "/" },
  {
    element: <MindMapPage />,
    errorElement: <ErrorPage />,
    path: "/mind_maps/:mindMapId",
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <GlobalContextProvider>
        <RouterProvider router={router} />
      </GlobalContextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
