import { useMemo, useState } from "react";
import getIsLevel1Node from "../helpers/getIsLevel1Node";
import { NodeType, VisibleNodeToolbar } from "../types";
import ColorNodeToolbar from "./ColorNodeToolbar";
import DeleteNodeToolbar from "./DeleteNodeToolbar";
import EditNodeToolbar from "./EditNodeToolbar";
import ExpandNodeToolbar from "./ExpandNodeToolbar";

export default function NodeToolbars({
  color,
  id,
  isEditing,
  selected,
  type,
}: {
  color: string | undefined;
  id: string;
  isEditing: boolean;
  selected: boolean | undefined;
  type: NodeType;
}) {
  const [visibleNodeToolbar, setVisibleNodeToolbar] =
    useState<VisibleNodeToolbar>(null);

  const isEditNodeToolbarVisible = !!isEditing && !!selected;

  const isLevel1Node = useMemo(() => getIsLevel1Node({ id, type }), [id, type]);
  const offsetForPrimaryNodeToolbar = isLevel1Node ? 20 : 15;
  const offsetForSecondaryNodeToolbars = isLevel1Node ? 65 : 60;

  return (
    <>
      <EditNodeToolbar
        color={color}
        id={id}
        isVisible={isEditNodeToolbarVisible}
        offset={offsetForPrimaryNodeToolbar}
        setVisibleNodeToolbar={setVisibleNodeToolbar}
        type={type}
        visibleNodeToolbar={visibleNodeToolbar}
      />

      <ColorNodeToolbar
        id={id}
        isVisible={isEditNodeToolbarVisible && visibleNodeToolbar === "colors"}
        offset={offsetForSecondaryNodeToolbars}
        setVisibleNodeToolbar={setVisibleNodeToolbar}
      />

      <DeleteNodeToolbar
        isVisible={isEditNodeToolbarVisible && visibleNodeToolbar === "delete"}
        offset={offsetForSecondaryNodeToolbars}
        setVisibleNodeToolbar={setVisibleNodeToolbar}
      />

      <ExpandNodeToolbar
        id={id}
        isVisible={isEditNodeToolbarVisible && visibleNodeToolbar === "expand"}
        offset={offsetForSecondaryNodeToolbars}
        setVisibleNodeToolbar={setVisibleNodeToolbar}
      />
    </>
  );
}
