export default function ManualInputIcon() {
  return (
    <svg
      fill="none"
      height="17"
      viewBox="0 0 18 17"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.69531 14.4141C1.28906 14.4141 0.96875 14.3021 0.734375 14.0781C0.5 13.8594 0.382812 13.5599 0.382812 13.1797C0.382812 12.9297 0.429688 12.6693 0.523438 12.3984L3.32031 4.5625C3.67969 3.53646 4.40365 3.02344 5.49219 3.02344C6.08073 3.02344 6.55469 3.15104 6.91406 3.40625C7.27344 3.65625 7.54427 4.04167 7.72656 4.5625L10.4688 12.2891C10.5677 12.5807 10.6172 12.8646 10.6172 13.1406C10.6172 13.526 10.4922 13.8333 10.2422 14.0625C9.99219 14.2969 9.65885 14.4141 9.24219 14.4141C8.86198 14.4141 8.5625 14.3229 8.34375 14.1406C8.125 13.9635 7.96094 13.6771 7.85156 13.2812L7.36719 11.7969H3.5625L3.07812 13.2812C2.97396 13.6771 2.8099 13.9635 2.58594 14.1406C2.36719 14.3229 2.07031 14.4141 1.69531 14.4141ZM4.10938 9.80469H6.82812L5.5 5.49219H5.4375L4.10938 9.80469ZM11.9609 16.9453C11.3672 16.9453 11.0703 16.6953 11.0703 16.1953C11.0703 15.7161 11.3672 15.4766 11.9609 15.4766H13.2578V1.84375H11.9609C11.3672 1.84375 11.0703 1.59375 11.0703 1.09375C11.0703 0.614583 11.3672 0.375 11.9609 0.375H16.1719C16.7656 0.375 17.0625 0.614583 17.0625 1.09375C17.0625 1.59375 16.7656 1.84375 16.1719 1.84375H14.9375V15.4766H16.1719C16.7656 15.4766 17.0625 15.7161 17.0625 16.1953C17.0625 16.6953 16.7656 16.9453 16.1719 16.9453H11.9609Z"
        fill="currentColor"
      />
    </svg>
  );
}
