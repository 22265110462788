import { Node } from "@xyflow/react";
import compareNodeIds from "./compareNodeIds";

export function groupNodeIds(nodes: Node[]) {
  const nodeIds = nodes
    .map((node) => node.id)
    .sort((a, b) => compareNodeIds(a, b));

  const result: (string | string[])[] = [];

  let currentGroup: string[] | null = null;

  nodeIds.forEach((nodeId) => {
    const level = nodeId.split(".").length;

    if (level === 1) {
      result.push(nodeId);
    } else if (level === 2) {
      if (currentGroup) {
        result.push(currentGroup);
      }

      result.push(nodeId);

      currentGroup = [];
    } else if (level > 2) {
      currentGroup?.push(nodeId);
    }
  });

  if (currentGroup) {
    result.push(currentGroup);
  }

  return result;
}
