import { useReactFlow } from "@xyflow/react";
import useGlobalContext from "./useGlobalContext";

export default function useUnhideAllNodes() {
  const { setIsExpanded } = useGlobalContext();
  const { setNodes } = useReactFlow();

  const unhideAllNodes = () => {
    setIsExpanded(true);

    setNodes((nodes) =>
      nodes.map((node) => {
        return {
          ...node,
          hidden: false,
        };
      }),
    );
  };

  return unhideAllNodes;
}
