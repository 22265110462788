import { Edge, Node } from "@xyflow/react";
import useGlobalContext from "./useGlobalContext";

export default function useAddVersion() {
  const { setVersionIndex, setVersions, versionIndex, versions } =
    useGlobalContext();

  const addVersion = ({ edges, nodes }: { edges: Edge[]; nodes: Node[] }) => {
    const sanitizedNodes = nodes.map((node) => ({
      ...node,
      hidden: false,
      selected: false,
    }));

    const newVersionIndex = versionIndex + 1;

    setVersionIndex(newVersionIndex);

    const newVersions = [
      ...versions.slice(0, newVersionIndex),
      { edges, nodes: sanitizedNodes },
    ];

    setVersions(newVersions);
  };

  return addVersion;
}
