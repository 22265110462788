import { useCallback, useEffect } from "react";
import useExport from "./useExport";
import useGlobalContext from "./useGlobalContext";
import useSetSupabaseSession from "./useSetSupabaseSession";

export default function useHandleMessage() {
  const { exportImageData } = useExport();
  const { setUserAccessToken } = useGlobalContext();
  const { setSupabaseSession } = useSetSupabaseSession();

  const setRefreshedAccessToken = useCallback(
    async (payload: { accessToken: string }) => {
      const { accessToken } = payload;

      await setSupabaseSession({ accessToken });

      setUserAccessToken(accessToken);
    },
    [setSupabaseSession, setUserAccessToken],
  );

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      if (typeof event.data === "string") {
        const { payload, type } = JSON.parse(event.data);

        switch (type) {
          case "REFRESHED_ACCESS_TOKEN":
            await setRefreshedAccessToken(payload);
            break;
          case "EXPORT_IMAGE_DATA":
            await exportImageData();
            break;
          default:
            console.error("Unhandled message type", type);
        }
      }
    },
    [exportImageData, setRefreshedAccessToken],
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);
}
