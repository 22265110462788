import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function AutoGrowingTextarea({
  className,
  name,
  onBlur,
  onChange,
  readOnly,
  value,
}: {
  className: string;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  readOnly: boolean;
  value: string;
}) {
  const { t } = useTranslation();

  const sharedClassName = `${className} bg-transparent border-0 p-1 text-center focus:ring-0 read-only:cursor-grab overflow-x-hidden`;

  useEffect(() => {
    const growers = document.querySelectorAll(".grow-wrap");

    growers.forEach((grower: Element) => {
      const divElement = grower as HTMLDivElement;
      const textarea = divElement.querySelector("textarea");

      divElement.dataset.replicatedValue = textarea?.value;

      textarea?.addEventListener("input", () => {
        divElement.dataset.replicatedValue = textarea.value;
      });
    });
  }, [value]);

  return (
    <div className={`${sharedClassName} grow-wrap`}>
      <textarea
        className={`${sharedClassName} ${readOnly ? "" : "nodrag"}`}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={t("writeHere")}
        readOnly={readOnly}
        rows={1}
        value={value}
      ></textarea>
    </div>
  );
}
