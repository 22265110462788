import { NodeToolbar } from "@xyflow/react";
import { useTranslation } from "react-i18next";
import useDeleteEdgesAndNodes from "../hooks/useDeleteEdgesAndNodes";
import { VisibleNodeToolbar } from "../types";

export default function DeleteNodeToolbar({
  isVisible,
  offset,
  setVisibleNodeToolbar,
}: {
  isVisible: boolean;
  offset: number;
  setVisibleNodeToolbar: (visibleNodeToolbar: VisibleNodeToolbar) => void;
}) {
  const deleteEdgesAndNodes = useDeleteEdgesAndNodes();
  const { t } = useTranslation();

  const handleCancelClick = () => {
    setVisibleNodeToolbar(null);
  };

  const handleDeleteClick = () => {
    setVisibleNodeToolbar(null);
    deleteEdgesAndNodes();
  };

  return (
    <NodeToolbar className="node-toolbar" offset={offset} isVisible={isVisible}>
      <button className="node-toolbar-button" onClick={handleCancelClick}>
        <span className="text-xs">{t("cancel")}</span>
      </button>

      <button className="node-toolbar-button" onClick={handleDeleteClick}>
        <span className="text-xs">{t("delete")}</span>
      </button>
    </NodeToolbar>
  );
}
