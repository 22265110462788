import { useReactFlow } from "@xyflow/react";
import { useParams } from "react-router-dom";
import { supabase } from "../helpers/supabase";

export default function usePersistEditedEdgesAndNodes() {
  const { getEdges, getNodes } = useReactFlow();
  const { mindMapId } = useParams();

  const persistEditedEdgesAndNodes = () => {
    // TODO: Persisting the correct edges and nodes is currently only working
    // using a timeout, because setEdges and setNodes need to be run first.
    setTimeout(async () => {
      const edges = getEdges();
      const nodes = getNodes();

      const deselectedNodes = nodes.map((node) => ({
        ...node,
        hidden: false,
        selected: false,
      }));

      const { error } = await supabase
        .from("mind_maps")
        .update({
          edited_react_flow_edges: edges,
          edited_react_flow_nodes: deselectedNodes,
        })
        .eq("id", mindMapId);

      if (error) {
        console.error("Error persisting edited edges and nodes", error);
        return;
      }
    }, 0);
  };

  return persistEditedEdgesAndNodes;
}
