import { useReactFlow } from "@xyflow/react";
import useGlobalContext from "./useGlobalContext";
import useUnhideAllNodes from "./useUnhideAllNodes";

declare global {
  interface Window {
    collapse: () => void;
    expand: () => void;
  }
}

export default function useCollapseAndExpand() {
  const unhideAllNodes = useUnhideAllNodes();
  const { fitView, setNodes } = useReactFlow();
  const { setIsExpanded } = useGlobalContext();

  const collapse = () => {
    setNodes((nodes) =>
      nodes.map((node) => {
        const hidden = node.id.split(".").length > 2;

        return { ...node, hidden: hidden };
      }),
    );

    // NOTE: Wait for the next event loop iteration, i.e. the edges and nodes states were set.
    setTimeout(() => {
      setIsExpanded(false);

      fitView({ duration: 1000 });
    }, 0);
  };

  const expand = () => {
    unhideAllNodes();

    // NOTE: Wait for the next event loop iteration, i.e. the edges and nodes states were set.
    setTimeout(() => {
      fitView({ duration: 1000 });
    }, 0);
  };

  return { collapse, expand };
}
