import * as Sentry from "@sentry/react";
import { supabase } from "./supabase";

export default async function getMindMapExpansionsCount() {
  const twentyFourHoursAgo = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000,
  );

  const { count, error } = await supabase
    .from("mind_map_expansions")
    .select("*", { count: "exact", head: true })
    .eq("user_id", "c8587ba6-173f-4b60-9050-3513c55e46b5")
    .gte("created_at", twentyFourHoursAgo.toISOString());

  if (error) {
    Sentry.captureException(
      `Failed to get mind map expansions count: ${error.message}`,
    );

    return 0;
  }

  return count || 0;
}
