import { Edge, Node, useReactFlow } from "@xyflow/react";
import useAddVersion from "./useAddVersion";
import useGlobalContext from "./useGlobalContext";
import useLayOutWithD3Hierarchy from "./useLayOutWithD3Hierarchy";
import usePersistEditedEdgesAndNodes from "./usePersistEditedEdgesAndNodes";

export default function useDeleteEdgesAndNodes() {
  const addVersion = useAddVersion();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();
  const { getLaidOutNodes } = useLayOutWithD3Hierarchy();
  const { setSelectedNodeId } = useGlobalContext();

  const deleteEdgesAndNodes = () => {
    const toBeDeletedNodeIds = getToBeDeletedNodeIds();

    if (!toBeDeletedNodeIds.length) return;

    const newEdges = getEdges().filter(
      (edge: Edge) =>
        !toBeDeletedNodeIds.includes(edge.source) &&
        !toBeDeletedNodeIds.includes(edge.target),
    );

    const newNodes = getNodes().filter(
      (node: Node) => !toBeDeletedNodeIds.includes(node.id),
    );

    const laidOutNodes = getLaidOutNodes({
      edges: newEdges,
      nodes: newNodes,
    });

    setEdges(newEdges);
    setNodes(laidOutNodes);

    // NOTE: Wait for the next event loop iteration, i.e. the edges and nodes states were set.
    setTimeout(() => {
      addVersion({ edges: newEdges, nodes: laidOutNodes });

      setSelectedNodeId(undefined);

      persistEditedEdgesAndNodes();
    }, 0);
  };

  const getToBeDeletedNodeIds = () => {
    const nodes = getNodes();

    const selectedNodeIds = nodes
      .filter((node: Node) => node.selected)
      .map((node: Node) => node.id);

    return nodes
      .filter((node: Node) =>
        selectedNodeIds.some((nodeId) => node.id.startsWith(nodeId)),
      )
      .map((node: Node) => node.id);
  };

  return deleteEdgesAndNodes;
}
