import { Edge, Node, NodeToolbar, useReactFlow } from "@xyflow/react";
import useAddVersion from "../hooks/useAddVersion";
import usePersistEditedEdgesAndNodes from "../hooks/usePersistEditedEdgesAndNodes";
import { VisibleNodeToolbar } from "../types";

export default function ColorNodeToolbar({
  id,
  isVisible,
  offset,
  setVisibleNodeToolbar,
}: {
  id: string;
  isVisible: boolean;
  offset: number;
  setVisibleNodeToolbar: (visibleNodeToolbar: VisibleNodeToolbar) => void;
}) {
  const addVersion = useAddVersion();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();

  const handleColorClick = (color: string, colorShimmer: string) => {
    setVisibleNodeToolbar(null);

    const edges = getEdges();
    const nodes = getNodes();

    const newNodes: Node[] = nodes.map((node) => {
      if (node.id.startsWith(id)) {
        return { ...node, data: { ...node.data, color, colorShimmer } };
      }

      return node;
    });

    const newEdges: Edge[] = edges.map((edge) => {
      if (edge.source.startsWith(id) || edge.target.startsWith(id)) {
        return { ...edge, data: { ...edge.data, color } };
      }

      return edge;
    });

    setEdges(newEdges);
    setNodes(newNodes);

    addVersion({ edges: newEdges, nodes: newNodes });

    persistEditedEdgesAndNodes();
  };

  return (
    <NodeToolbar
      className="node-toolbar !divide-x-0"
      offset={offset}
      isVisible={isVisible}
    >
      <button
        className="node-toolbar-button"
        onClick={() => handleColorClick("#B0ECFF", "#303A43")}
      >
        <span className="node-node-toolbar-button-span bg-custom-blue" />
      </button>

      <button
        className="node-toolbar-button"
        onClick={() => handleColorClick("#FFE890", "#393A34")}
      >
        <span className="node-node-toolbar-button-span bg-custom-yellow" />
      </button>

      <button
        className="node-toolbar-button"
        onClick={() => handleColorClick("#D1AAFF", "#323142")}
      >
        <span className="node-node-toolbar-button-span bg-custom-purple" />
      </button>

      <button
        className="node-toolbar-button"
        onClick={() => handleColorClick("#FFB3B3", "#383238")}
      >
        <span className="node-node-toolbar-button-span bg-custom-red" />
      </button>

      <button
        className="node-toolbar-button"
        onClick={() => handleColorClick("#A2E2A9", "#2E3837")}
      >
        <span className="node-node-toolbar-button-span bg-custom-green" />
      </button>

      <button
        className="node-toolbar-button"
        onClick={() => handleColorClick("#FFF9D8", "#2C2E32")}
      >
        <span className="node-node-toolbar-button-span bg-custom-beige" />
      </button>

      <button
        className="node-toolbar-button"
        onClick={() => handleColorClick("#FFFFFF", "#3A3D43")}
      >
        <span className="node-node-toolbar-button-span bg-white" />
      </button>
    </NodeToolbar>
  );
}
