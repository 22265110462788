import { useContext } from "react";
import { GlobalContext, GlobalContextProps } from "../context/GlobalContext";

export default function useGlobalContext(): GlobalContextProps {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }

  return context;
}
