import * as Sentry from "@sentry/react";
import { Edge, Node, useReactFlow } from "@xyflow/react";
import { supabase } from "../helpers/supabase";
import useAddVersion from "./useAddVersion";
import useGlobalContext from "./useGlobalContext";
import useLayOutWithD3Hierarchy from "./useLayOutWithD3Hierarchy";
import usePersistEditedEdgesAndNodes from "./usePersistEditedEdgesAndNodes";

export default function useAddGeneratedNodes() {
  const addVersion = useAddVersion();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { fitBounds, getEdges, getNodesBounds, getNodes, setEdges, setNodes } =
    useReactFlow();
  const { getLaidOutNodes } = useLayOutWithD3Hierarchy();
  const { setIsAddingGeneratedNodes } = useGlobalContext();

  const addGeneratedNodes = async ({
    mindMapId,
    nodeId,
  }: {
    mindMapId: string;
    nodeId: string;
  }) => {
    setIsAddingGeneratedNodes(true);

    const { data, error } = await supabase.functions.invoke("expand-mind-map", {
      body: {
        mindMapId,
        nodeId,
      },
    });

    if (error) {
      Sentry.captureException(
        `Failed to add generated nodes: ${error.message}`,
      );

      setIsAddingGeneratedNodes(false);

      return;
    }

    const { reactFlowEdges: generatedEdges, reactFlowNodes: generatedNodes } =
      data;

    const generatedNodesWithSanitizedTypes = generatedNodes.map(
      (node: Node) => ({
        ...node,
        type: getLevel({ generatedEdges, nodeId: node.id }),
      }),
    );

    const edges = [...getEdges(), ...generatedEdges];
    const nodes = [...getNodes(), ...generatedNodesWithSanitizedTypes];

    const laidOutNodes = getLaidOutNodes({ edges, nodes });

    setNodes(laidOutNodes);
    setEdges(edges);

    // NOTE: Wait for the next event loop iteration, i.e. the edges and nodes states were set.
    setTimeout(() => {
      const nodesBounds = getNodesBounds(generatedNodes);

      fitBounds(nodesBounds, { duration: 1000 });

      addVersion({ edges: edges, nodes: laidOutNodes });

      persistEditedEdgesAndNodes();
    }, 1000);

    setIsAddingGeneratedNodes(false);
  };

  const getLevel = ({
    generatedEdges,
    nodeId,
  }: {
    generatedEdges: Edge[];
    nodeId: string;
  }) => {
    const generatedEdgesFromNodeCount = generatedEdges.filter(
      (edge: Edge) => edge.source === nodeId,
    ).length;

    switch (generatedEdgesFromNodeCount) {
      case 0:
        return "level-4";
      case 1:
        return "level-3";
      default:
        return "level-2";
    }
  };

  return addGeneratedNodes;
}
