import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

type Error = {
  message?: string;
};

export default function ErrorPage() {
  const error = useRouteError() as Error;

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  console.error(error);

  return (
    <div className="flex h-full flex-col items-center justify-center space-y-8 text-white">
      <h1>Oops</h1>

      <p>An error occured</p>

      <p className="text-center italic">{error.message}</p>
    </div>
  );
}
