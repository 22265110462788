import ColoredEdge from "../components/ColoredEdge";
import Level1Node from "../components/Level1Node";
import Level2Node from "../components/Level2Node";
import Level3Node from "../components/Level3Node";
import Level4Node from "../components/Level4Node";

export const edgeTypes = {
  default: ColoredEdge,
};

export const nodeTypes = {
  "level-1": Level1Node,
  "level-2": Level2Node,
  "level-3": Level3Node,
  "level-4": Level4Node,
};
