import { useReactFlow } from "@xyflow/react";
import useAddVersion from "./useAddVersion";
import useCenterNode from "./useCenterNode";
import useLayOutWithD3Hierarchy from "./useLayOutWithD3Hierarchy";
import usePersistEditedEdgesAndNodes from "./usePersistEditedEdgesAndNodes";

export default function useAddEmptyNode() {
  const addVersion = useAddVersion();
  const centerNode = useCenterNode();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();
  const { getLaidOutNodes } = useLayOutWithD3Hierarchy();

  const addEmptyNode = ({ parentNodeId }: { parentNodeId: string }) => {
    const parentNode = getNodes().find((node) => node.id === parentNodeId);

    if (!parentNode) return;

    const newNodeId = getNextChildNodeId(parentNodeId);
    const newNodeType = getNextChildNodeType(parentNode.type);

    const newNode = {
      data: { label: "" },
      id: newNodeId,
      position: { x: 0, y: 0 },
      selected: true,
      type: newNodeType,
    };

    const newEdge = {
      id: `${parentNodeId}-${newNodeId}`,
      source: parentNodeId,
      target: newNodeId,
    };

    const existingNodes = getNodes().map((node) => ({
      ...node,
      selected: false,
    }));

    const edges = [...getEdges(), newEdge];
    const nodes = [...existingNodes, newNode];

    const laidOutNodes = getLaidOutNodes({ edges, nodes });

    setNodes(laidOutNodes);
    setEdges(edges);

    // NOTE: Wait for the next event loop iteration, i.e. the edges and nodes states were set.
    setTimeout(() => {
      centerNode(laidOutNodes.find((node) => node.id === newNodeId));

      addVersion({ edges: edges, nodes: laidOutNodes });

      persistEditedEdgesAndNodes();
    }, 0);
  };

  const getNextChildNodeId = (parentId: string) => {
    const nodeIds = getNodes().map((node) => node.id);

    let childIndex = 1;
    let nextId = `${parentId}.${childIndex}`;

    while (nodeIds.includes(nextId)) {
      childIndex++;
      nextId = `${parentId}.${childIndex}`;
    }

    return nextId;
  };

  const getNextChildNodeType = (parentNodeType: string | undefined) => {
    if (!parentNodeType) return "level-2";

    const parentNodeTypeIndex = Number(parentNodeType.split("-")[1]);

    if (parentNodeTypeIndex === 4) return "level-2";

    return `level-${parentNodeTypeIndex + 1}`;
  };

  return addEmptyNode;
}
