export default function compareNodeIds(
  aId: string | undefined,
  bId: string | undefined,
): number {
  const aParts = aId?.split(".")?.map(Number) ?? [];
  const bParts = bId?.split(".")?.map(Number) ?? [];

  const len = Math.max(aParts.length, bParts.length);

  for (let i = 0; i < len; i++) {
    const aPart = aParts[i] ?? -Infinity;
    const bPart = bParts[i] ?? -Infinity;

    if (aPart !== bPart) {
      return aPart - bPart;
    }
  }

  return 0;
}
