import { Node, useReactFlow } from "@xyflow/react";
import useAddVersion from "./useAddVersion";
import usePersistEditedEdgesAndNodes from "./usePersistEditedEdgesAndNodes";

export default function useSaveNode() {
  const addVersion = useAddVersion();
  const persistEditedEdgesAndNodes = usePersistEditedEdgesAndNodes();
  const { getEdges, getNodes, setNodes } = useReactFlow();

  const saveNode = ({
    id,
    valueNew,
    valueOld,
  }: {
    id: string;
    valueNew: string;
    valueOld: string;
  }) => {
    if (valueNew === valueOld) return;

    // NOTE: Set new nodes
    const nodes = getNodes();

    const newNodes: Node[] = nodes.map((node) => {
      if (node.id === id) {
        return { ...node, data: { ...node.data, label: valueNew } };
      }
      return node;
    });

    setNodes(newNodes);

    addVersion({ edges: getEdges(), nodes: newNodes });

    persistEditedEdgesAndNodes();
  };

  return saveNode;
}
