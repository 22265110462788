import { NodeType } from "../types";

export default function getIsLevel1Node({
  id,
  type,
}: {
  id: string;
  type: NodeType;
}) {
  return id === "1" || type === "level-1";
}
