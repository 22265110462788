import { BaseEdge, getSimpleBezierPath } from "@xyflow/react";
import { getColor } from "../helpers/colors";

type ColoredEdgeProps = {
  data?: { color?: string };
  id: string;
  sourceX: number;
  sourceY: number;
  target: string;
  targetX: number;
  targetY: number;
};

export default function ColoredEdge({
  data,
  id,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
}: ColoredEdgeProps) {
  const [edgePath] = getSimpleBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const strokeColor = data?.color || getColor(target);

  return (
    <BaseEdge
      id={id}
      markerStart="START"
      path={edgePath}
      style={{ stroke: strokeColor, strokeWidth: "2px" }}
    />
  );
}
