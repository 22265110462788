export const getBgColor = (id: string) => {
  switch (matchId(id)) {
    case 1:
      return "bg-custom-blue";
    case 2:
      return "bg-custom-yellow";
    case 3:
      return "bg-custom-purple";
    case 4:
      return "bg-custom-red";
    case 5:
      return "bg-custom-green";
    case 6:
      return "bg-custom-beige";
    default:
      return "bg-white";
  }
};

export const getBgColorShimmer = (id: string) => {
  switch (matchId(id)) {
    case 1:
      return "bg-custom-blue-shimmer";
    case 2:
      return "bg-custom-yellow-shimmer";
    case 3:
      return "bg-custom-purple-shimmer";
    case 4:
      return "bg-custom-red-shimmer";
    case 5:
      return "bg-custom-green-shimmer";
    case 6:
      return "bg-custom-beige-shimmer";
    default:
      return "bg-custom-white-shimmer";
  }
};

export const getBorderColor = (id: string) => {
  switch (matchId(id)) {
    case 1:
      return "border-custom-blue";
    case 2:
      return "border-custom-yellow";
    case 3:
      return "border-custom-purple";
    case 4:
      return "border-custom-red";
    case 5:
      return "border-custom-green";
    case 6:
      return "border-custom-beige";
    default:
      return "border-white";
  }
};

// TODO: Reuse the colors from the Tailwind config theme,
// instead of redefining them here.
export const getColor = (id: string) => {
  switch (matchId(id)) {
    case 1:
      return "#B0ECFF"; // custom-blue
    case 2:
      return "#FFE890"; // custom-yellow
    case 3:
      return "#D1AAFF"; // custom-purple
    case 4:
      return "#FFB3B3"; // custom-red
    case 5:
      return "#A2E2A9"; // custom-green
    case 6:
      return "#FFF9D8"; // custom-beige
    default:
      return "#FFFFFF"; // white
  }
};

export const getTextColor = (id: string) => {
  switch (matchId(id)) {
    case 1:
      return "text-custom-blue";
    case 2:
      return "text-custom-yellow";
    case 3:
      return "text-custom-purple";
    case 4:
      return "text-custom-red";
    case 5:
      return "text-custom-green";
    case 6:
      return "text-custom-beige";
    default:
      return "text-white";
  }
};

const matchId = (id: string) => {
  return Number(id.split(".")[1]);
};
