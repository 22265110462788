import { Handle, Position } from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import { getBgColorShimmer, getBorderColor } from "../helpers/colors";
import useGlobalContext from "../hooks/useGlobalContext";
import useSaveNode from "../hooks/useSaveNode";
import { NodeProps } from "../types";
import AutoGrowingTextarea from "./AutoGrowingTextarea";
import NodeToolbars from "./NodeToolbars";

export default function Level3Node({ data, id, selected, type }: NodeProps) {
  const [label, setLabel] = useState<string>(data.label);

  const saveNode = useSaveNode();
  const { isEditing } = useGlobalContext();

  const bgColorShimmer = getBgColorShimmer(id);

  const borderColor = getBorderColor(id);

  const outline =
    isEditing && selected
      ? "outline-dotted outline-2 outline-offset-4 outline-white"
      : "";

  const overridingStyle: React.CSSProperties = {};

  if (data?.color) overridingStyle.borderColor = data.color;
  if (data?.colorShimmer) overridingStyle.backgroundColor = data.colorShimmer;

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      saveNode({ id, valueNew: event.target.value, valueOld: data.label });
    },
    [data.label, id, saveNode],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      setLabel(value);
    },
    [],
  );

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  return (
    <div
      className={`${bgColorShimmer} ${borderColor} ${outline} max-w-96 rounded-full border-2 px-6 py-4 text-white`}
      style={overridingStyle}
    >
      <NodeToolbars
        color={data.color}
        id={id}
        isEditing={isEditing}
        selected={selected}
        type={type}
      />

      <Handle type="target" position={Position.Top} />

      <AutoGrowingTextarea
        className="text-xl font-semibold"
        name="label"
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={!isEditing}
        value={label}
      />

      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}
