export default function usePostIsEditingToReactNativeWebView() {
  const postIsEditingToReactNativeWebView = (isEditing: boolean) => {
    if (window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "isEditing", value: isEditing }),
      );
    }
  };

  return postIsEditingToReactNativeWebView;
}
