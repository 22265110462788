import * as Sentry from "@sentry/react";
import { supabase } from "../helpers/supabase";
import useGlobalContext from "./useGlobalContext";

export default function useSetSupabaseSession() {
  const { setSession } = useGlobalContext();

  async function setSupabaseSession({ accessToken }: { accessToken: string }) {
    // NOTE: We are setting an invalid refresh token to prevent the Supabase
    // client from using it and invalidate it for the mobile app.
    const { data, error } = await supabase.auth.setSession({
      access_token: accessToken,
      refresh_token: "INVALID_REFRESH_TOKEN",
    });

    if (error) {
      Sentry.captureException(
        `Failed to set Supabase session: ${error.message}`,
      );
    } else {
      setSession(data.session);
    }
  }

  return { setSupabaseSession };
}
