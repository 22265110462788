import { Node, useReactFlow } from "@xyflow/react";

export default function useCenterNode() {
  const { setCenter } = useReactFlow();

  const centerNode = (node: Node | undefined) => {
    if (!node) return;

    setCenter(
      node.position.x + (node?.measured?.width || 0) / 2,
      node.position.y + (node?.measured?.height || 0) / 2,
      {
        duration: 1000,
        zoom: 0.6,
      },
    );
  };

  return centerNode;
}
