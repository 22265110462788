import { SparklesIcon } from "@heroicons/react/24/solid";
import { NodeToolbar } from "@xyflow/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import getMindMapExpansionsCount from "../helpers/getMindMapExpansionsCount";
import useAddEmptyNode from "../hooks/useAddEmptyNode";
import useAddGeneratedNodes from "../hooks/useAddGeneratedNodes";
import usePostMindMapExpansionsLimitReachedToReactNativeWebView from "../hooks/usePostMindMapExpansionsLimitReachedToReactNativeWebView";
import ManualInputIcon from "../icons/ManualInputIcon";
import { VisibleNodeToolbar } from "../types";

const MIND_MAP_EXPANSIONS_LIMIT = 10;

export default function ExpandNodeToolbar({
  id,
  isVisible,
  offset,
  setVisibleNodeToolbar,
}: {
  id: string;
  isVisible: boolean;
  offset: number;
  setVisibleNodeToolbar: (visibleNodeToolbar: VisibleNodeToolbar) => void;
}) {
  const addEmptyNode = useAddEmptyNode();
  const addGeneratedNodes = useAddGeneratedNodes();
  const params = useParams();
  const { t } = useTranslation();
  const postMindMapExpansionsLimitReachedToReactNativeWebView =
    usePostMindMapExpansionsLimitReachedToReactNativeWebView();

  const handleExpandWithAi = async () => {
    setVisibleNodeToolbar(null);

    const mindMapExpansionsCount = await getMindMapExpansionsCount();

    if (mindMapExpansionsCount < MIND_MAP_EXPANSIONS_LIMIT) {
      addGeneratedNodes({
        mindMapId: params.mindMapId as string,
        nodeId: id,
      });
    } else {
      postMindMapExpansionsLimitReachedToReactNativeWebView(true);
    }
  };

  const handleExpandManually = () => {
    setVisibleNodeToolbar(null);

    addEmptyNode({ parentNodeId: id });
  };

  return (
    <NodeToolbar className="node-toolbar" offset={offset} isVisible={isVisible}>
      <button className="node-toolbar-button" onClick={handleExpandWithAi}>
        <SparklesIcon className="h-6 w-6" />

        <span className="text-xs">{t("expandWithAi")}</span>
      </button>

      <button className="node-toolbar-button" onClick={handleExpandManually}>
        <ManualInputIcon />

        <span className="text-xs">{t("manualInput")}</span>
      </button>
    </NodeToolbar>
  );
}
