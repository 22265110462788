import { CheckIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { NodeToolbar, useReactFlow } from "@xyflow/react";
import { useMemo } from "react";
import { getBgColor } from "../helpers/colors";
import getIsLevel1Node from "../helpers/getIsLevel1Node";
import useGlobalContext from "../hooks/useGlobalContext";
import { NodeType, VisibleNodeToolbar } from "../types";
import Spinner from "./Spinner";

export default function EditNodeToolbar({
  color,
  id,
  isVisible,
  offset,
  setVisibleNodeToolbar,
  type,
  visibleNodeToolbar,
}: {
  color: string | undefined;
  id: string;
  isVisible: boolean;
  offset: number;
  setVisibleNodeToolbar: (visibleNodeToolbar: VisibleNodeToolbar) => void;
  type: NodeType;
  visibleNodeToolbar: VisibleNodeToolbar;
}) {
  const { isAddingGeneratedNodes } = useGlobalContext();
  const { setNodes } = useReactFlow();

  const isLevel1Node = useMemo(() => getIsLevel1Node({ id, type }), [id, type]);

  const handleClose = () => {
    setVisibleNodeToolbar(null);

    setNodes((nodes) =>
      nodes.map((node) => ({
        ...node,
        selected: false,
      })),
    );
  };

  const toggleColorsNodeToolbar = () => {
    setVisibleNodeToolbar(visibleNodeToolbar === "colors" ? null : "colors");
  };

  const toggleDeleteNodeToolbar = () => {
    setVisibleNodeToolbar(visibleNodeToolbar === "delete" ? null : "delete");
  };

  const toggleExpandNodeToolbar = () => {
    setVisibleNodeToolbar(visibleNodeToolbar === "expand" ? null : "expand");
  };

  return (
    <NodeToolbar className="node-toolbar" offset={offset} isVisible={isVisible}>
      {!isLevel1Node && (
        <>
          <button
            className={`node-toolbar-button ${visibleNodeToolbar === "colors" ? "!bg-custom-black" : ""}`}
            disabled={isAddingGeneratedNodes}
            onClick={toggleColorsNodeToolbar}
          >
            <span
              className={`node-node-toolbar-button-span ${getBgColor(id)}`}
              style={color ? { backgroundColor: color } : {}}
            />
          </button>

          <button
            className={`node-toolbar-button ${visibleNodeToolbar === "delete" ? "!bg-custom-black" : ""}`}
            disabled={isAddingGeneratedNodes}
            onClick={toggleDeleteNodeToolbar}
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </>
      )}

      <button
        className={`node-toolbar-button ${visibleNodeToolbar === "expand" ? "!bg-custom-black" : ""}`}
        disabled={isAddingGeneratedNodes}
        onClick={toggleExpandNodeToolbar}
      >
        {isAddingGeneratedNodes ? (
          <Spinner className="h-6 w-6" />
        ) : (
          <PlusIcon className="h-6 w-6" />
        )}
      </button>

      <button
        className="node-toolbar-button"
        disabled={isAddingGeneratedNodes}
        onClick={handleClose}
      >
        <CheckIcon className="h-6 w-6" />
      </button>
    </NodeToolbar>
  );
}
