import { Handle, Position } from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import useGlobalContext from "../hooks/useGlobalContext";
import useSaveNode from "../hooks/useSaveNode";
import { NodeProps } from "../types";
import AutoGrowingTextarea from "./AutoGrowingTextarea";
import NodeToolbars from "./NodeToolbars";

export default function Level1Node({ data, id, selected, type }: NodeProps) {
  const [label, setLabel] = useState<string>(data.label);

  const saveNode = useSaveNode();
  const { isEditing } = useGlobalContext();

  const animatePulse = data.initial && "animate-pulse";

  const outline =
    isEditing && selected
      ? "outline-dotted outline-2 outline-offset-4 outline-white"
      : "";

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      saveNode({ id, valueNew: event.target.value, valueOld: data.label });
    },
    [data.label, id, saveNode],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setLabel(event.target.value);
    },
    [],
  );

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  return (
    <div
      className={`${animatePulse} ${outline} max-w-96 rounded-3xl border-4 border-custom-black-light bg-custom-black p-10 text-white`}
    >
      <NodeToolbars
        color={data.color}
        id={id}
        isEditing={isEditing}
        selected={selected}
        type={type}
      />

      <div className="absolute left-0 top-0 -translate-y-1/2 translate-x-full rounded-full border-4 border-custom-black-light bg-custom-black px-4 py-2 text-lg text-custom-gray">
        VisualMind
      </div>

      <Handle type="target" position={Position.Top} />

      <AutoGrowingTextarea
        className="text-4xl"
        name="label"
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={!isEditing}
        value={label}
      />

      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}
