import { ReactFlowProvider } from "@xyflow/react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import MindMap from "../components/MindMap";
import useGlobalContext from "../hooks/useGlobalContext";
import useSetSupabaseSession from "../hooks/useSetSupabaseSession";

export default function MindMapPage() {
  const [searchParams] = useSearchParams();

  const { setSupabaseSession } = useSetSupabaseSession();
  const { setUserAccessToken, setUserIsPro } = useGlobalContext();

  useEffect(() => {
    setUserIsPro(searchParams.get("pro") === "true");

    const accessToken = searchParams.get("access");

    if (accessToken) {
      setUserAccessToken(accessToken);

      setSupabaseSession({ accessToken });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-dvh w-screen flex-col">
      <ReactFlowProvider>
        <MindMap />
      </ReactFlowProvider>
    </div>
  );
}
