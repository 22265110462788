import { Node, useOnSelectionChange, useReactFlow } from "@xyflow/react";
import { useCallback } from "react";
import useGlobalContext from "./useGlobalContext";

export default function useHandleSelectedNode() {
  const { setNodes } = useReactFlow();
  const { setSelectedNodeId } = useGlobalContext();

  const handleSelectionChange = useCallback(
    ({ nodes }: { nodes: Node[] }) => {
      const selectedNodeId = nodes[0]?.id;

      setSelectedNodeId(selectedNodeId);

      setNodes((nodes: Node[]) =>
        nodes.map((node: Node) => ({
          ...node,
          selected: node.id === selectedNodeId,
        })),
      );
    },
    [setNodes, setSelectedNodeId],
  );

  useOnSelectionChange({ onChange: handleSelectionChange });
}
