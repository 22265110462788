export default function usePostExpansionsLimitReachedToReactNativeWebView() {
  const postMindMapExpansionsLimitReachedToReactNativeWebView = (
    mindMapExpansionsLimitReached: boolean,
  ) => {
    if (window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "mindMapExpansionsLimitReached",
          value: mindMapExpansionsLimitReached,
        }),
      );
    }
  };

  return postMindMapExpansionsLimitReachedToReactNativeWebView;
}
